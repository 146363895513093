/* ===== Features CSS ===== */

.ud-features {
  padding-top: 120px;
  padding-bottom: 90px;

  @media #{$xs} {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

.ud-section-title {
  max-width: 620px;
  margin-bottom: 70px;

  span {
    font-weight: 600;
    font-size: 18px;
    color: var(--primary-color);
    margin-bottom: 10px;
  }

  h2 {
    font-weight: 700;
    font-size: 42px;
    line-height: 55px;
    color: var(--heading-color);
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    line-height: 30px;

    @media #{$xs} {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

.ud-single-feature {
  margin-bottom: 40px;

  &:hover {
    .ud-feature-icon::before {
      @include transform(rotate(45deg));
    }
  }

  .ud-feature-icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background: var(--primary-color);
    font-size: 32px;
    color: var(--white);
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 40px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 14px;
      background: var(--primary-color);
      opacity: 0.2;
      z-index: -1;
      @include transform(rotate(23deg));
      @include transition(0.3s);
    }
  }

  .ud-feature-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;

    a {
      color: var(--heading-color);
      &:hover {
        color: var(--primary-color);
      }
    }

    @media #{$lg} {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .ud-feature-desc {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .ud-feature-link {
    font-weight: 500;
    color: var(--body-color);

    &:hover {
      color: var(--primary-color);
    }
  }
}
